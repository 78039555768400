import React from "react"
import Home from '../components/Home';

import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Home />
  </>
)

export default IndexPage
