import React from "react"
import AnimocaImage from "../../images/animoca.gif"
import Prosus from "../../images/prosus.png"

const Partner = () => {
  const partners = [
    {
      title: "Animoca Brands",
      image: AnimocaImage,
      link: "https://www.animocabrands.com/",
      imageStyle: {
        height: "130px",
      },
    },
    {
      title: "Prosus",
      image: Prosus,
      link: "https://www.prosus.com/",
      imageStyle: {
        height: "130px",
      },
    },
  ]

  const Item = ({ partner }) => {
    return (
      <a
        style={{
          display: "flex",
          alignItems: "center",
          height: "180px",
          marginRight: "60px",
        }}
        href={partner.link}
        target="_blank"
      >
        <img
          style={{
            ...partner.imageStyle,
          }}
          src={partner.image}
          alt={partner.title}
        />
      </a>
    )
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "150px",
      }}
    >
      <div>
        <div
          style={{
            fontSize: "30px",
            fontWeight: 300,
            lineHeight: "100%",
            letterSpacing: "-0.01em",
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "left",
          }}
        >
          Partners:
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "1100px",
            maxWidth: "90vw",
          }}
          className="partner-item"
        >
          {partners.map(partner => (
            <Item partner={partner} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Partner
