import React from "react"
import RobertoImage from "../../images/punk-1785.png"
import MattImage from "../../images/matt.png"
import LinkedinImage from "../../images/linkedin.svg"

const Team = () => {
  const teams = [
    {
      title: "Roberto Ceresia",
      role: "CEO",
      image: RobertoImage,
      link: "https://www.linkedin.com/in/robertoceresia/",
      linkImage: LinkedinImage,
      imageStyle: {
        filter: "grayscale(1)",
      },
    },
    {
      title: "Matthieu Daulhiac",
      role: "Blockchain Engineer",
      image: MattImage,
      linkImage: LinkedinImage,
    },
  ]

  const Item = ({ team }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${team.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            marginBottom: "24px",
            ...team.imageStyle,
          }}
          className="member-img"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="member-width"
        >
          <div
            style={{
              fontSize: "36px",
              fontFamily: "Graphik SemiBold",
            }}
          >
            {team.title}
          </div>
          {team.link && (
            <a target="_blank" href={team.link}>
              <img
                src={team.linkImage}
                style={{ height: "18px", width: "18px" }}
              />
            </a>
          )}
        </div>
        <div
          style={{
            textAlign: "left",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "30px",
            lineHeight: "100%",
            marginTop: "10px",
          }}
          className="member-width"
        >
          {team.role}
        </div>
      </div>
    )
  }

  return (
    <div className="team-member">
      <div>
        <div
          style={{
            fontSize: "30px",
            fontWeight: 300,
            lineHeight: "100%",
            letterSpacing: "-0.01em",
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "left",
          }}
        >
          Team:
        </div>
        <div className="member">
          {teams.map(team => (
            <Item team={team} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Team
