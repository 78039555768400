import React from 'react';
import backgroundImg from "../../images/banner.gif";
import lauch from '../../images/etherscan-logo.png';

const Top = () => {
  return (
    <div>
      <div className="top-text">
        <div>
          sm<span style={{ color: '#00C2FF' }}>art</span>er contracts
        </div>
        <div style={{ fontSize: '24px', marginTop: '40px' }}>
          blanknetwork is a pioneering NFT company based in Hong Kong. We build next generation tools for the Art & Creative industry.
        </div>
      </div>
      <img src={backgroundImg} style={{ width: '100%' }}/>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
        <div style={{ fontSize: '14px', fontWeight: 400, marginTop: '10px'}}>
          Fluid - @thesarahshow
        </div>
        <a
          href="https://etherscan.io/address/0xd07dc4262bcdbf85190c01c996b4c06a461d2430"
          target="_blank"
        >
          <img
            src={lauch}
            style={{
              width: '16px',
              height: '16px',
              position: 'relative',
              left: '7px',
              top: '3px',
            }}
          />
        </a>
      </div>
    </div>
  );
};

export default Top;
