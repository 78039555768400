import React, { useState } from 'react';

const Contact = () => {
  const [values, setValues] = useState({})
  const hanldeSubmit = () => {
    alert("Your message has been sent successfully!");
    setValues({ name: '', email: '', message: '' });
  }
  return (
    <div
      style={{
        height: 'calc(100vh - 110px - 64px)',
        padingTop: '50px',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          fontSize: "56px",
          fontWeight: 500,
        }}
      >
        <a
          href="mailto:team@blanknetwork.com"
          style={{
            color: "#000",
            textDecoration: "none",
          }}
          target="_blank"
        >
          Contact us
        </a>
        {/* <div style={{ maxWidth: '736px', margin: 'auto' }}>
          <div style={{ display: 'flex', gap: '30px', paddingTop: '60px' }}>
            <input
              placeholder="Name"
              style={{flex: 1}}
              value={values['name']}
              onChange={(e) => setValues({ ... values, name: e.target.value })}
            />
            <input
              placeholder="Email"
              style={{flex: 1}}
              value={values['email']}
              onChange={(e) => setValues({ ... values, email: e.target.value })}
            />
          </div>
          <div style={{ display: 'flex', margin: '24px 0' }}>
            <textarea
              placeholder="Message"
              rows="4"
              style={{ width: '100%', height: '168px', padding: '17px 30px'}}
              value={values['message']}
              onChange={(e) => setValues({ ... values, message: e.target.value })}
            />
          </div>
          <button className="submit-btn" onClick={hanldeSubmit}>
            Submit
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Contact;
